






























































import { Component, Vue } from "vue-property-decorator";
import VModal from "vue-js-modal";
import VueIntercom from "@mathieustan/vue-intercom";
import axios from "axios";

import siteFooter from "../components/footer.vue";
import config from "../config.json";

Vue.use(VModal);
Vue.use(VueIntercom);

const api = axios.create({ baseURL: "/api/" });

@Component({ components: { siteFooter } })
export default class Signup extends Vue {
  toolIcons = require("../assets/tools/icons/*.svg");
  tools: Record<string, { label: string, supported?: boolean, required?: boolean }> = config.tools;
  toolGroups: Record<string, { label: string, description: string, options: string[] }> = config.toolGroups;

  signup: Record<string, any> = null;
  submitSent = false;
  error: any = "";

  form = {
    tools: <Record<string, string[]>> { issue: [], code: [], comms: [], wiki: [] },
    otherTools: <Record<string, string[]>>{ issue: [], code: [], comms: [], wiki: [] },
  };

  get checklistIsValid(): boolean {
    return !!(this.form.tools.issue.length || this.form.tools.code.length);
  }

  get toolAssesment() {
    const uniqSelections = Object.values(this.form.tools).reduce((acc, ns) => acc.concat(ns.filter(n => !acc.includes(n))), []);
    const assessment = uniqSelections.reduce(
      (acc, tname) => {
        this.tools[tname].required && acc.required.push(tname);
        this.tools[tname].supported && acc.supported.push(tname) || acc.unsupported.push(tname);
        return acc;
      },
      { required: [], supported: [], unsupported: [] }
    );
    return assessment;
  }

  addOtherTool(gname: string): void {
    if (!this.form.otherTools[gname].length || this.form.otherTools[gname][this.form.otherTools[gname].length - 1]) {
      this.form.otherTools[gname].push("");
    }
    const field = `other-${gname}`;
    this.$nextTick(() => this.focusField(field));
  }

  removeOtherTool(gname: string, index: number): void {
    this.form.otherTools[gname].splice(index, 1);
  }

  checkOtherTool(gname: string, index: number): void {
    const value = this.sanitizeString(this.form.otherTools[gname][index]);
    const existing = this.toolGroups[gname].options.find((opt: string) => opt === value);
    if (!value || existing) {
      this.removeOtherTool(gname, index);
      if (existing && !this.form.tools[gname].includes(value)) {
        this.form.tools[gname].push(value);
      }
    }
  }

  toolListToString(list: string[]) {
    const toolLabels = list.map(tname => this.tools[tname].label);
    const lastLabel = toolLabels.pop();
    return toolLabels.length ? [toolLabels.join(", "), lastLabel].join(" and ") : lastLabel;
  }

  focusField(field: string): void {
    if (Array.isArray(this.$refs[field])) {
      this.$refs[field][this.$refs[field].length - 1].focus();
    } else {
      this.$refs[field].focus();
    }
  }

  sanitizeString(string: string): string {
    return string.toLowerCase().replace(/\W/g, "-");
  }

  async updateSignup(): Promise<void> {
    this.error = "";
    this.submitSent = true;

    try {
      if (!this.checklistIsValid) {
        throw new Error("Please select at least 1 tool");
      }

      await api.post(`signup/${this.signup.Id}/tools`, this.form);

      if (!this.toolAssesment.required.length) {
        this.$router.push({ name: "signup.thanks" });
        return;
      }

      if (!!this.toolAssesment.unsupported.length) {
        this.$modal.show("unsupported-tools");
        return;
      }

      this.createCustomer();
    } catch(error) {
      this.submitSent = false;
      const response = error && (error.response && error.response.data || error.message || error);
      this.error = response || "There's been an error";
    }
  }

  async createCustomer(): Promise<void> {
    this.$modal.hide("unsupported-tools");
    try {
      await api.post(`signup/${this.signup.Id}/workspace`);

      this.$intercom.shutdown();
      this.$intercom.boot({
        email: this.signup.Customer.email,
        name: this.signup.Customer.firstname,
        company: {
          id: this.signup.Workspace.id,
          name: this.signup.Customer.company,
        },
      });

      this.$router.push({ name: "signup.password" });
    } catch(error) {
      const response = error && (error.response && error.response.data || error.message || error);
      this.error = response || "There's been an error";
    }
  }

  async mounted(): Promise<void> {
    const signupId = sessionStorage.getItem("signupId");

    if (!signupId) {
      this.$router.push({ name: "signup" });
      return;
    }

    try {
      const response = await api.get(`signup/${signupId}`);
      this.signup = response.data.signup;
      if (this.signup.DesiredTools) {
        this.form = this.signup.DesiredTools;
      }
    } catch(error) {
      this.error = error.response?.data || error.message || "There's been an error";
    }
  }
}
