


































































































































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import moment from "moment-timezone";

import siteFooter from "../components/footer.vue";
import config from "../config.json";

const api = axios.create({ baseURL: "/api/" });

@Component({ components: { siteFooter } })
export default class Signup extends Vue {
  toolLogos = require("../assets/tools/*.svg");
  tools: Record<string, { label: string, supported?: boolean, required?: boolean }> = config.tools;
  surveyQuestions: Record<string, { label: string, options: string[], allowOther?: boolean }> = config.surveyQuestions;

  signup: Record<string, any> = null;
  workspace: Record<string, any> = null;
  expectedTools: Record<string, Record<string, any>> = null;
  statusTimeout: NodeJS.Timeout = null;
  submitTimeout: NodeJS.Timeout = null;
  submitSent = false;
  survey: Record<string, string> = {};
  error: any = "";

  get surveyIsComplete(): boolean {
    return Object.keys(this.surveyQuestions).length === Object.values(this.survey).filter(v => v).length;
  }

  surveyUpdated() {
    clearTimeout(this.submitTimeout);
    this.submitTimeout = setTimeout(this.saveSurvey, 3000);
  }

  async saveSurvey() {
    this.submitTimeout = null;
    this.submitSent = true;
    try {
      await api.post(`signup/${this.signup.Id}/survey`, this.survey);
    } catch(error) {
      this.error = error.response?.data || error.message || "There's been an error";
    }
    this.submitSent = false;
  }

  async checkWorkspaceStatus() {
    try {
      const response = await api.get(`signup/${this.signup.Id}/workspace`);
      this.workspace = response.data;

      const elapsedMins = moment().diff(moment(this.workspace.timeCreated), "minutes");
      this.workspace.minutesLeft = config.spinupEstimateMins - elapsedMins;

      if (!this.workspace.ready && !this.workspace.needsApproval) {
        const delay = this.workspace.minutesLeft < 1 ? 5000 : 15000;
        setTimeout(this.checkWorkspaceStatus, delay);
      }
    } catch(error) {
      this.signup = null;
      this.error = error.response?.data || error.message || "There's been an error";
    }
  }

  gotoWorkspace() {
    if (this.workspace?.url) {
      window.location.href = `${this.workspace.url}login?user=${this.signup.Customer.email}`;
    }
  }

  async mounted(): Promise<void> {
    const signupId = sessionStorage.getItem("signupId");

    if (!signupId) {
      this.$router.push({ name: "signup" });
      return;
    }

    try {
      const response = await api.get(`signup/${signupId}`);
      this.signup = response.data.signup;

      if (this.signup.DesiredTools?.tools) {
        this.expectedTools = Object.entries(this.signup.DesiredTools.tools).reduce((acc, [type, tools]: [string, string[]]) => {
          const expectedTools = tools.filter(tool => this.tools[tool]?.supported);
          return expectedTools.length ? Object.assign(acc, { [type]: expectedTools }) : acc;
        }, {});
      }

      if (this.signup.Survey) {
        this.survey = this.signup.Survey;
      }

      this.checkWorkspaceStatus();
    } catch(error) {
      this.error = error.response?.data || error.message || "There's been an error";
    }
  }
}
