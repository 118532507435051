















import { Component, Vue } from "vue-property-decorator";
import VueIntercom from "@mathieustan/vue-intercom";

Vue.use(VueIntercom, { appId: "d42kqvuj" });

@Component
export default class Footer extends Vue {
  mounted(): void {
    this.$intercom.boot();
  }

  showIntercom(): void {
    this.$intercom.show();
  }
}
