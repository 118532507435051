













import { Component, Vue } from "vue-property-decorator";
import axios from "axios";

import siteFooter from "../components/footer.vue";

const api = axios.create({ baseURL: "/api/" });

@Component({ components: { siteFooter } })
export default class Signup extends Vue {
  heading = "Email verification";
  description = "Hold tight while we verify your details.";

  async mounted(): Promise<void> {
    try {
      const response = await api.post("signup/verify", { token: this.$route.query.t });
      sessionStorage.setItem("signupId", response.data.signupId);
      this.$router.push({ name: "signup.checklist" });
    }
    catch(error) {
      sessionStorage.removeItem("signupId");
      const response = error && error.response && error.response.data;
      this.heading = "There's been a problem";
      this.description = response || "There's been an error";
    }
  }
}
