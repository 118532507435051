








































































































































































































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import VueIntercom from "@mathieustan/vue-intercom";

import siteFooter from "../components/footer.vue";

Vue.use(VueIntercom);

const api = axios.create({ baseURL: "/api/" });

@Component({ components: { siteFooter } })
export default class Pricing extends Vue {
  icons = require("../assets/icons/*.svg");
  productQty = 1;
  period = "year";
  activeTooltip: string | null = null;
  products: Record<string, any>[] = null;
  $intercom: any;
  featureKey: string[] | undefined;

  discount = {
    promotionCode: <Record<string, any>>null,
    code: "",
    input: false,
    checking: false,
    error: "",
  };

  featureList = [
    {
      title: `ojo<sup class="tm">™</sup>`,
      subtitle: "Agile co-pilot",
    },
    {
      fields: [
        {
          title: "Active Cycle Tracker:",
          description: "Highlights major practice improvements and declines per iteration; calls out driving practices most affecting scores.",
          featureKey: ["activecycle", "activecycle:tracker"],
        },
        {
          title: "Active Cycle Planner & Reviewer:",
          description: "Automated observations & suggestions embedded throughout each Active Cycle tool to accelerate learning and take aligned action.",
          featureKey: ["activecycle", "activecycle:planner", "activecycle:reviewer"],
        },
        {
          title: "Macro Cycle Tracker, Planner & Reviewer + Playbooks:",
          description:
            "Master performance across longer timeframes with recommended Goals, supporting driver targets, and detailed analysis of what’s impacting your performance over time.",
          featureKey: ["macrocycle", "playbooks"],
        },
      ],
    },
    {
      title: "Active Cycle Toolkit",
      subtitle: "Data-inspired insights to support sprint/kanban iteration performance",
    },
    {
      categoryTitle: "Tracker",
      categorySubtitle: "Visualise and measure iteration performance, including:",
    },
    {
      subcategoryTitle: "Reports",
      subcategorySubtitle: "Zoom-out for top-line results that visualise team iteration performance across:",
      featureKey: ["activecycle", "activecycle:tracker"],
    },
    {
      fields: [
        {
          title: "25 agile metrics",
          description: "Grouped by Design & Build, Review, and Engagement workflows and mapped to agility outcomes e.g. Speed, Progress",
        },
        {
          title: "Benchmarks",
          description: "Actual scores updated daily & benchmarked to the team’s usual performance (6 sprints rolling median)",
        },
        {
          title: `ojo<sup class="tm">™</sup> Insights`,
          description: "Outlier scores (both improvements and declines) highlighted for focus and investigation",
        },
        {
          title: "Report filters",
          description: "Customise team/user view on which metrics matter most",
        },
      ],
    },
    {
      subcategoryTitle: "Metric charts",
      subcategorySubtitle: "For each metric, zoom in for deeper learning & context before action:",
      featureKey: ["activecycle", "activecycle:tracker"],
    },
    {
      fields: [
        {
          title: "Charts",
          description: "Compare actual scores with benchmarks reported over iterations; customisable views for metric subsets and timeframes",
        },
        {
          title: `ojo<sup class="tm">™</sup> Insights`,
          description: "Calls out major improvements and declines, including reasons for change; widgets add deeper insight",
        },
        {
          title: "Surfacing table",
          description:
            "Scores each work item (e.g. issues, PRs, etc) for the selected iteration and selected metric, providing context for the aggregated team score for that iteration, and highlighting outliers to address",
        },
      ],
    },
    {
      categoryTitle: "Planner",
      categorySubtitle: "Build insight-driven plans that you can confidently deliver, including:",
      featureKey: ["activecycle", "activecycle:planner"],
    },
    {
      fields: [
        {
          title: "Planning guide",
          description: `Like a calculator, compare the future iteration’ plan with your usual; ojo<sup class="tm">™</sup> calls out where you’re over, under, or a match`,
        },
        {
          title: "Completion predictions",
          description:
            "Presents median cycle time for your estimate sizes, along with the number of issues assigned with that estimate so you can know what to add/remove to build a plan you know you can deliver",
        },
        {
          title: "Planning inputs to edit and course correct",
          description: "A widget with planning variables the team can edit to view how changing their plan impacts delivery goals",
        },
      ],
    },
    {
      categoryTitle: "Reviewer",
      categorySubtitle: "Build best practice retros and reflective practice, including:",
    },
    {
      subcategoryTitle: "Team vibe",
      subcategorySubtitle: "Customisable health check polls/surveys to capture sentiment related to values, culture, process, tools... or anything the metrics do not",
      featureKey: ["activecycle", "activecycle:reviewer"],
    },
    {
      subcategoryTitle: "Team input",
      subcategorySubtitle: "Capture retro comments and actions",
      featureKey: ["activecycle", "activecycle:reviewer"],
    },
    {
      subcategoryTitle: `ojo<sup class="tm">™</sup> Insights`,
      subcategorySubtitle: "Highlights outlier metrics that your team over or underperformed on, offering suggestions to improve",
      featureKey: ["activecycle", "activecycle:reviewer"],
    },
    {
      title: "Macro Cycle ToolKit",
      subtitle: "Quarterly or customisable longer-term planning, measurement & reviewing",
    },
    {
      categoryTitle: "Planner",
      categorySubtitle: "Builds insight-driven longer-term plans that you can confidently deliver, including:",
      featureKey: ["macrocycle"],
    },
    {
      fields: [
        {
          title: "Planning guide",
        },
        {
          title: "Completion predictions",
        },
        {
          title: "Planning inputs",
        },
      ],
    },
    {
      categoryTitle: "Tracker",
    },
    {
      subcategoryTitle: "Reports",
      subcategorySubtitle: "Zoom-out for top-line results that visualise team iteration performance across, including:",
      featureKey: ["macrocycle"],
    },
    {
      fields: [
        {
          title: "25 agile metrics",
          description: "Grouped by Design & Build, Review, and Engagement workflows and mapped to agility outcomes e.g. Speed, Progress",
        },
        {
          title: "Benchmarks",
          description: `
            <p>
              Actual scores updated at the closure of each iteration & benchmarked to either the team’s
            </p>
            <ul>
              <li class="highlightedBulletText"}>Peak performance profile</li>
              <li class="highlightedBulletText"}>Any prior quarter</li>
              <li>
                <span class="highlightedBulletText"}>Run rates</span> indicating
                consistency of practice, and ability to maintain & sustain performance
              </li>
            </ul>
          `,
        },
        {
          title: `ojo<sup class="tm">™</sup> Insights`,
          description: "Outlier scores (both improvements and declines) highlighted for focus and investigation",
        },
        {
          title: "Report filters",
          description: "Customise team/user view on which metrics matter most",
        },
      ],
    },
    {
      subcategoryTitle: "Metric charts",
      featureKey: ["macrocycle"],
    },
    {
      fields: [
        {
          title: "View quarterly performance vs selected comparison period",
        },
        {
          title: "View performance related to Goals",
        },
        {
          title: "View related Guardrails and Practices",
        },
      ],
    },
    {
      categoryTitle: "Reviewer",
      categorySubtitle: "Build best practice quarterly reviews and reflective practice, including:",
    },
    {
      subcategoryTitle: "Team vibe",
      subcategorySubtitle: "Customisable health check polls/surveys to capture sentiment related to values, culture, process, tools... or anything the metrics do not",
      featureKey: ["macrocycle"],
    },
    {
      subcategoryTitle: "Team input",
      subcategorySubtitle: "Capture team comments and actions for the next quarter",
      featureKey: ["macrocycle"],
    },
    {
      subcategoryTitle: `ojo<sup class="tm">™</sup> Insights`,
      subcategorySubtitle: "Highlights outlier metrics that your team over or underperformed on, offering suggestions to improve",
      featureKey: ["macrocycle"],
    },
    {
      title: "Playbooks",
      subtitle: "Create and run a play with any or all of the components, including:",
    },
    {
      subcategoryTitle: "Goals",
      subcategorySubtitle: "Set metric-based Goal to focus on agility outcome improvements, like Speed or Quality",
      featureKey: ["playbooks"],
    },
    {
      subcategoryTitle: "Guardrails",
      subcategorySubtitle: "Set a target range for metrics-related practices that define your team’s preferred way of working",
      featureKey: ["playbooks"],
    },
    {
      subcategoryTitle: "Practices",
      subcategorySubtitle: "Codify your general work practices to align ways of working; link to Goals and Guardrails where relevant",
      featureKey: ["playbooks"],
    },
    {
      title: "Notes",
      subtitle: "Pin comments to any page to capture team narrative & context at any point in time",
      featureKey: ["notes"],
    },
    {
      title: "Data & Insights History",
      featureDataHistory: true,
    },
  ];

  toggleBillingPeriod(): void {
    this.period = this.period === "year" ? "month" : "year";
  }

  yearVsMonthSavings(productPrice: any): string {
    const yearMonthlyAmount = productPrice.year.unit_amount / 12;
    const saving = Math.round(100 - (yearMonthlyAmount / productPrice.month.unit_amount) * 100);
    return `${saving}%`;
  }

  findProduct(productId: string): Record<string, any> {
    return this.products.find(p => (p.id = productId)) || {};
  }

  updateProductQty(change: number): void {
    const newTotal = this.productQty + change;
    this.productQty = newTotal > 0 ? newTotal : 1;
  }

  formatCents(cents: number) {
    const rounded = Math.round(cents / 100);
    const formatted = rounded.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return `\$${formatted}`;
  }

  formatPrice(price: Record<string, any>, displayPeriod: "month" | "year", qty: number = 1): string {
    const coupon = this.discount.promotionCode?.coupon;
    const applyCoupon = coupon && coupon.duration !== "once" && this.discountAppliesToProduct(price.product);

    const subtotal = price.unit_amount * qty;
    const total = applyCoupon ? subtotal - (coupon.amount_off || subtotal * (coupon.percent_off / 100)) : subtotal;

    const periodDivisor = price.recurring.interval === "year" && displayPeriod === "month" ? 12 : 1;
    return this.formatCents(total / periodDivisor);
  }

  selectPrice(productId: string): void {
    const product = this.products.find(p => p.id === productId);
    const price = product.periodPrice[this.period];
    const params = {
      productName: product.name,
      priceId: price.id,
      trialDays: product.metadata.trial_days,
      promotionCodeId: (this.discountAppliesToProduct(productId) && this.discount.promotionCode?.id) || undefined,
    };
    this.$router.push({ name: "signup", params });
  }

  contactForProduct(productName: string): void {
    this.$intercom.showNewMessage(`Hi, I'm interested in Umano's ${productName} subscription`);
  }

  async applyDiscount(): Promise<void> {
    this.discount.checking = true;
    this.discount.error = "";

    try {
      const response = await api.get(`billing/promo/${this.discount.code}`);
      const promotionCode = response.data;
      if (!promotionCode.id) {
        throw new Error("This promotion code does not exist.");
      }
      if (!promotionCode.active) {
        throw new Error("This promotion code is no longer valid.");
      }
      if (promotionCode.coupon.applies_to?.products) {
        while (!this.products) {
          await new Promise(resolve => setTimeout(resolve, 500));
        }
        if (!this.products.map(p => p.id).some(pid => promotionCode.coupon.applies_to.products.includes(pid))) {
          throw new Error("This promotion code cannot be applied to any of the available products.");
        }
      }
      this.discount.promotionCode = promotionCode;
    } catch (error) {
      this.discount.error = error.response?.data || error.message || "There's been an error";
    }

    this.discount.checking = false;
  }

  discountAppliesToProduct(productId: string) {
    const appliesToProducts = this.discount.promotionCode?.coupon.applies_to?.products;
    return this.discount.promotionCode && (!appliesToProducts || appliesToProducts.includes(productId));
  }

  adjustColsTop() {
    const tops = document.querySelectorAll(".pricing-showcase > div .top");
    const headings = document.querySelectorAll(".pricing-showcase > div .pricing");
    [tops, headings].forEach(elems => {
      const tallestElem = Math.max(...Array.from(elems).map((elem: HTMLElement) => elem.offsetHeight));
      elems.forEach((elem: HTMLElement) => {
        elem.style.minHeight = `${tallestElem}px`;
      });
    });
  }

  showTooltip(field: string) {
    this.activeTooltip = field;
  }

  hideTooltip() {
    this.activeTooltip = null;
  }

  isFeatureIncluded(product: any, featureKey: string[]) {
    return product.metadata.umano_features?.split(",").some((feature: string) => featureKey && featureKey.includes(feature));
  }

  iconForFeature(product: any, featureKey: string[]) {
    const hasFeature = this.isFeatureIncluded(product, featureKey);
    return hasFeature ? this.icons["check-circle-filled"] : this.icons.cross;
  }

  async mounted(): Promise<void> {
    sessionStorage.removeItem("signupId");

    if (this.$route.query.promo) {
      this.discount.code = this.$route.query.promo.toString();
      this.applyDiscount();
    }

    const response = await api.get("billing/products");
    this.products = response.data.sort((a: any, b: any) => {
      const priceA = a.prices.find((price: any) => price.recurring?.interval === "month");
      const priceB = b.prices.find((price: any) => price.recurring?.interval === "month");

      return (priceA?.unit_amount || 0) - (priceB?.unit_amount || 0);
    });
    this.products.forEach(product => {
      if (product.prices.length) {
        product.periodPrice = {
          month: product.prices.find((p: any) => p.recurring.interval === "month"),
          year: product.prices.find((p: any) => p.recurring.interval === "year"),
        };
      }
      product.features = product.metadata.features?.split("\n");
    });

    setTimeout(this.adjustColsTop);
  }
}
