





























import { Component, Watch, Prop, Vue } from "vue-property-decorator";

@Component
export default class TutorialVideo extends Vue {
  @Prop() private src!: string;
  @Prop() private intro!: string;
  @Prop() private introTime!: number;
  @Prop() private next!: Function;

  videoElement: HTMLVideoElement = null;
  videoEnded = false;
  userPaused: boolean = null;
  showIntro = true;
  playTimeout: NodeJS.Timeout = null;

  @Watch("src")
  srcChanged() {
    this.reset();
  }

  eventHandler(event: any) {
    switch (event.type) {
      case "loadeddata": this.loadeddata(event.target); break;
      case "click": this.click(); break;
      case "ended": this.ended(); break;
      default: break;
    }
  }

  loadeddata(element: HTMLVideoElement) {
    this.videoElement = element;
    clearTimeout(this.playTimeout);
    const introTimeout = this.intro ? (this.introTime || 5) * 1000 : 0;
    this.playTimeout = setTimeout(this.hideIntro, introTimeout);
  }

  click() {
    this.videoElement.paused ? this.videoElement.play() : this.videoElement.pause();
    this.userPaused = this.videoElement.paused;
  }

  ended() {
    this.videoEnded = true;
  }

  play() {
    this.videoEnded = false;
    this.videoElement.play();
  }

  reset() {
    this.videoEnded = false;
    this.showIntro = true;
  }

  hideIntro() {
    clearTimeout(this.playTimeout);
    this.showIntro = false;
    this.playTimeout = setTimeout(this.play, 3000);
  }
}
