import Vue from "vue";
import VueRouter from "vue-router";
import admin from "./views/admin.vue";
import pricing from "./views/pricing.vue";
import signup from "./views/signup.vue";
import signupVerify from "./views/signup-verify.vue";
import signupChecklist from "./views/signup-checklist.vue";
import signupPassword from "./views/signup-password.vue";
import signupCredsAtlassian from "./views/signup-credentials-atlassian.vue";
import signupStatus from "./views/signup-status.vue";
import signupThanks from "./views/signup-thanks.vue";
import tutorialCredentials from "./views/tutorial-credentials.vue";
import err404 from "./views/404.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/pricing",
  },
  {
    path: "/admin",
    name: "admin",
    component: admin,
    meta: { title: "Admin" },
  },
  {
    path: "/pricing",
    name: "pricing",
    component: pricing,
    meta: { title: "Get Started with Umano" },
  },
  {
    path: "/sign-up",
    name: "signup",
    component: signup,
    meta: { title: "Get Started with Umano" },
  },
  {
    path: "/sign-up/verify",
    name: "signup.verify",
    component: signupVerify,
    meta: { title: "Verify your Email" },
  },
  {
    path: "/sign-up/checklist",
    name: "signup.checklist",
    component: signupChecklist,
    meta: { title: "Setup Umano" },
  },
  {
    path: "/sign-up/password",
    name: "signup.password",
    component: signupPassword,
    meta: { title: "Set a Password" },
  },
  {
    path: "/sign-up/credentials/atlassian",
    name: "signup.credentials.atlassian",
    component: signupCredsAtlassian,
    meta: { title: "Connect to Jira & Confluence" },
  },
  {
    path: "/sign-up/status",
    name: "signup.status",
    component: signupStatus,
    meta: { title: "Thank you" },
  },
  {
    path: "/sign-up/thanks",
    name: "signup.thanks",
    component: signupThanks,
    meta: { title: "Thank you" },
  },
  {
    path: "/tutorial/credentials/:tool?",
    name: "tutorialCredentials",
    component: tutorialCredentials,
    meta: { title: "Getting Tool Credentials" },
  },
  {
    path: "*",
    name: "err404",
    component: err404,
    meta: { title: "Page Not Found" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: () => ({ x: 0, y: 0 }),
});

router.afterEach((to, from) => {
  document.title = to.meta && to.meta.title ? `${to.meta.title} - Umano` : "Umano";
});

export default router;
