




















































import { Component, Vue } from "vue-property-decorator";
import Vuelidate from "vuelidate";
import { Validations } from "vuelidate-property-decorators";
import { required, email, helpers } from "vuelidate/lib/validators";
import axios from "axios";

import siteFooter from "../components/footer.vue";

Vue.use(Vuelidate);

const api = axios.create({ baseURL: "/api/" });

@Component({ components: { siteFooter } })
export default class Signup extends Vue {
  signup: Record<string, any> = null;
  submitSent = false;
  error: any = "";
  siteDomain = ".atlassian.net";

  form = {
    site: "",
    email: "",
    token: "",
  };

  @Validations()
  validations = {
    form: {
      site: {
        required,
        regex: helpers.regex("site", /^[a-z0-9][a-z0-9-]{1,99}[a-z0-9]$/),
      },
      email: { required, email },
      token: { required },
    },
  };

  siteInput(): void {
    let site = this.form.site.toLowerCase();
    site = site.match(/^https?:\/\/.{2,}/g) ? site.replace(/^https?:\/\//g, "") : site;
    site = site.includes(this.siteDomain) && site.split(this.siteDomain)[0] || site;
    this.form.site = site;
  }

  async updateSignup(): Promise<void> {
    this.$v.form.$touch();
    if (this.$v.form.$invalid) {
      return;
    }

    this.error = "";
    this.submitSent = true;

    try {
      const response = await api.post(`signup/${this.signup.Id}/tools/atlassian`, this.form);

      if (!response.data.siteValid) {
        throw new Error("The site URL cannot be reached. Please check it and try again.");
      }

      if (!response.data.authValid.jira) {
        throw new Error("The credentials provided are invalid or incorrect. Please check them and try again.");
      }

      if (!response.data.saved) {
        throw new Error("There's an issue saving your API token, please contact support.");
      }

      this.$router.push({ name: "signup.status" });
    } catch(error) {
      this.submitSent = false;
      const response = error && (error.response && error.response.data || error.message || error);
      this.error = response || "There's been an error";
    }
  }

  async mounted(): Promise<void> {
    const signupId = sessionStorage.getItem("signupId");

    if (!signupId) {
      this.$router.push({ name: "signup" });
      return;
    }

    try {
      const response = await api.get(`signup/${signupId}`);
      this.signup = response.data.signup;
      this.form.email = this.signup.Customer.email;
    } catch(error) {
      this.error = error.response?.data || error.message || "There's been an error";
    }
  }
}
