































import { Component, Vue } from "vue-property-decorator";
import * as Password from "vue-password-strength-meter";
import axios from "axios";

import siteFooter from "../components/footer.vue";

Vue.use(Password);

const api = axios.create({ baseURL: "/api/" });

@Component({ components: { Password, siteFooter } })
export default class Signup extends Vue {
  signup: Record<string, any> = null;
  passwordScore: null | number = null;
  submitSent = false;
  error: any = "";

  form = {
    password: "",
  };

  get passwordIsValid(): boolean {
    return !!(this.form.password && this.passwordScore > 1);
  }

  updateScore(score: null | number) {
    this.passwordScore = score;
  }

  updateFeedback(feedback: Record<string, string>) {
    this.error = feedback.warning;
  }

  async updateSignup(): Promise<void> {
    this.error = "";
    this.submitSent = true;

    try {
      if (!this.passwordIsValid) {
        throw new Error("Please enter a valid password");
      }

      await api.post(`signup/${this.signup.Id}/password`, this.form);

      if (this.signup.DesiredTools?.tools.issue?.includes("jira-software")) {
        this.$router.push({ name: "signup.credentials.atlassian" });
      } else {
        this.$router.push({ name: "signup.status" });
      }
    } catch(error) {
      this.submitSent = false;
      this.error = error.response?.data || error.message || "There's been an error";
    }
  }

  async mounted(): Promise<void> {
    const signupId = sessionStorage.getItem("signupId");

    if (!signupId) {
      this.$router.push({ name: "signup" });
      return;
    }

    try {
      const response = await api.get(`signup/${signupId}`);
      this.signup = response.data.signup;
    } catch(error) {
      this.error = error.response?.data || error.message || "There's been an error";
    }
  }
}
