




















































import { Component, Vue } from "vue-property-decorator";
import tutorialVideo from "../components/tutorial-video.vue";

@Component({ components: { tutorialVideo } })
export default class TutorialCredentials extends Vue {
  toolAssets = require("../assets/tools/*.svg");
  videos = require("../assets/tutorial/*.mp4");

  step = 1;
  tools = [
    {
      slug: "atlassian-cloud",
      name: "JIRA & Confluence Cloud",
      assets: [this.toolAssets["jira-software"], this.toolAssets["confluence"]],
      credentialType: "API token",
      steps: [
        {
          video: this.videos["atlassian-cloud-1"],
          text: `<a target="_blank" href="https://id.atlassian.com/manage-profile/security/api-tokens">Go to Atlassian API Tokens management</a>.
          Alternatively from within JIRA or Confluence, go to your Atlassian security settings to manage API tokens.`,
        },
        {
          video: this.videos["atlassian-cloud-2"],
          text: "Create a new API token and then copy it to a text document where you can access it later.",
        },
        {
          text: "Don't forget to keep your API token in a temporary place (eg. a text document) as you will need it to complete Umano's setup once we notify you your workspace is ready.",
        },
      ],
      viewed: false,
    },
  ];
  oauthTools = [
    {
      slug: "bitbucket-cloud",
      name: "Bitbucket Cloud",
      assets: [this.toolAssets["bitbucket"]],
    },
    {
      slug: "github",
      name: "GitHub",
      assets: [this.toolAssets["github"]],
    },
    {
      slug: "gitlab",
      name: "GitLab",
      assets: [this.toolAssets["gitlab"]],
    },
    {
      slug: "slack",
      name: "Slack",
      assets: [this.toolAssets["slack"]],
    },
  ];

  get tool(): any {
    return this.tools.find(t => t.slug === this.$route.params.tool);
  }

  get stepContent() {
    return this.tool && this.tool.steps && this.tool.steps[this.step - 1];
  }

  viewTool(tool: string = null) {
    this.step = 1;
    this.$router.push({ params: { tool } });
  }

  prevStep() {
    this.step -= 1;
  }

  nextStep() {
    this.step += 1;
  }

  finish() {
    this.tool.viewed = true;
    this.viewTool();
  }
}
