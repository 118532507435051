










































































import { Component, Vue } from "vue-property-decorator";
import Vuelidate from "vuelidate";
import { Validations } from "vuelidate-property-decorators";
import { required, email, helpers } from "vuelidate/lib/validators";
import { VueReCaptcha } from "vue-recaptcha-v3";
import axios from "axios";
import moment from "moment-timezone";
import { recaptcha } from "../../config.json";

import siteFooter from "../components/footer.vue";

Vue.use(VueReCaptcha, { siteKey: recaptcha.siteKey });
Vue.use(Vuelidate);

const api = axios.create({ baseURL: "/api/" });

@Component({ components: { siteFooter } })
export default class Signup extends Vue {
  productName = "";
  trialDays = "";
  form = {
    firstname: "",
    email: "",
    company: "",
    workspace: "",
    priceId: "",
    promotionCodeId: "",
    timezone: moment.tz.guess(),
    "g-recaptcha-response": "",
  };
  workspace = {
    domain: ".umano.tech",
    auto: true,
    maxLength: 100,
  };
  serverValidation = {
    field: "",
    message: "",
  };
  submitSent = false;
  submitSuccess = false;
  error: any = "";

  @Validations()
  validations = {
    form: {
      firstname: { required },
      email: { required, email },
      company: { required },
      workspace: {
        required,
        regex: helpers.regex("workspace", /^[a-z][a-z0-9]{1,99}$/),
        server: () => this.serverValidation.field !== "workspace",
      },
    },
  };

  clearServerValidation(field: string): void {
    if (this.serverValidation.field === field) {
      this.serverValidation.field = "";
      this.serverValidation.message = "";
    }
  }

  focusField(field: string): void {
    if (field === "workspace" && this.workspace.auto) {
      this.workspace.auto = false;
      this.$v.form.workspace.$touch();
      setTimeout(() => this.focusField(field), 100);
    }
    this.$refs[field].focus();
  }

  sanitizeWorkspace(): void {
    if (this.workspace.auto) {
      this.form.workspace = this.form.company
        .toLowerCase()
        .replace(/[^a-z0-9]/g, "")
        .slice(0, this.workspace.maxLength);
    }
  }

  checkAutoWorkspace(): void {
    if (this.form.company && (!this.form.workspace || this.workspace.auto && this.$v.form.workspace.$invalid)) {
      this.focusField("workspace");
    }
  }

  workspaceInput(): void {
    this.form.workspace = this.form.workspace.toLowerCase();
    this.clearServerValidation("workspace");
  }

  async signup(): Promise<void> {
    this.$v.form.$touch();
    if (this.$v.form.$invalid) {
      return;
    }
    this.error = "";
    this.submitSent = true;

    try {
      const recaptchaToken = await this.$recaptcha("signup");
      this.form["g-recaptcha-response"] = recaptchaToken;

      const postResponse = await api.post("signup", this.form);
      if (postResponse && postResponse.data) {
        this.submitSuccess = true;
      }
    }
    catch(error) {
      this.submitSent = false;
      const response = error && error.response && error.response.data;

      if (response && response.field) {
        this.serverValidation.field = response.field;
        this.serverValidation.message = response.message;
        this.focusField(response.field);
      } else {
        this.error = response || "There's been an error";
      }
    }
  }

  mounted(): void {
    if (this.$route.query.email) {
      this.form.email = this.$route.query.email.toString();
      this.$router.replace({ query: {} });
    }

    if (this.$route.params.priceId) {
      const { productName, priceId, trialDays, promotionCodeId } = this.$route.params;
      Object.assign(this, { productName, trialDays });
      Object.assign(this.form, { priceId, promotionCodeId });
    } else {
      this.$router.replace({ name: "pricing" });
    }
  }
}
