

















import { Component, Vue } from "vue-property-decorator";

import siteFooter from "../components/footer.vue";

@Component({ components: { siteFooter } })
export default class Signup extends Vue {
  mounted(): void {
    const signupId = sessionStorage.getItem("signupId");
    if (!signupId) {
      this.$router.push({ name: "signup" });
      return;
    }
  }
}
