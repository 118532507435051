
















































































































































































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import moment from "moment-timezone";
import { customer } from "../../config.json";

const api = axios.create({ baseURL: "/api/" });

@Component
export default class Admin extends Vue {
  customers: Record<string, any>[] = [];
  isLoading = false;
  customerStatus = customer.status;
  apiKey = "";
  listFilter = "";
  sort = { property: "stack.updateTime", ascending: false };
  errorMessage = "";

  formType: string = null;
  form = {
    firstname: "",
    email: "",
    company: "",
    workspace: "",
    version: { analyser: "", ingester: "", frontend: "", feproxied: "" },
    analyserMemory: "",
    forceUpdate: false,
    useHybrid: false,
    priority: "",
    timezone: moment.tz.guess(),
    "g-recaptcha-response": "",
  };
  submitSent = false;
  submitSuccess = false;
  submitResult: any = "";
  lastVersionUsed: any = null;

  get isCreateForm() {
    return this.formType === "create";
  }

  get sortedCustomers() {
    const props = this.sort.property.split(".");
    const modifier = this.sort.ascending ? 1 : -1;
    return this.customers
      .filter(c => !this.listFilter || [c.key, c.name, ...Object.values(c.contact)].some(p => String(p).toLowerCase().includes(this.listFilter.toLowerCase())))
      .sort((a: any, b: any) => {
        const aVal = props.length === 2 && a[props[0]] ? a[props[0]][props[1]] : a[props[0]];
        const bVal = props.length === 2 && b[props[0]] ? b[props[0]][props[1]] : b[props[0]];
        return (aVal < bVal && -1 || aVal > bVal && 1 || 0) * modifier;
      });
  }

  get selectedWorkspace() {
    if (this.form.workspace) {
      const customer = this.customers.find(c => c.key === this.form.workspace);
      const version = {
        analyser: customer.stack.params.AnalyserVersion,
        ingester: customer.stack.params.IngesterVersion,
        frontend: customer.stack.params.FrontendVersion,
        feproxied: customer.stack.params.FeProxiedVersion,
      };
      const analyserMemory = customer.stack.params.AnalyserMemory;
      const isHybrid = this.form.useHybrid || !!version.feproxied
      return { key: customer.key, version, analyserMemory, isHybrid };
    }
    return null;
  }

  sortCustomers(sortBy: string, ascendingFirst: boolean = true) {
    this.sort.ascending = sortBy === this.sort.property ? !this.sort.ascending : ascendingFirst;
    this.sort.property = sortBy;
  }

  sortedClass(property: string) {
    const sorted = property === this.sort.property;
    return { sorted, ascending: sorted && this.sort.ascending };
  }

  loadCustomers() {
    this.isLoading = true;
    this.errorMessage = "";

    api.get("/customers", { headers: { apiKey: this.apiKey } }).then(response => {
      this.customers = response.data.map((c: any) => ({
        key: c.CustomerKey,
        name: c.CustomerName,
        contact: {
          firstname: c.ContactFirstName,
          email: c.ContactEmail,
          tz: c.DeviceTimezone,
        },
        status: c.Status,
        workspace: <Record<string, any>>null,
        creationTime: moment(c.TimeCreated),
        stack: c.Stack
          ? {
              name: c.Stack.StackName,
              status: c.Stack.StackStatus,
              creationTime: moment(c.Stack.CreationTime),
              updateTime: moment(c.Stack.LastUpdatedTime || c.Stack.CreationTime),
              params: c.Stack.Parameters.reduce((o: any, p: any) => Object.assign(o, { [p.ParameterKey]: p.ParameterValue }), {}),
            }
          : null,
        errorDump: c.ErrorDump,
      }));
    }).catch((error) => {
      const errorMsg = error.response && error.response.data && error.response.data.message || error.message;
      this.errorMessage = `Cannot load customers: ${errorMsg}`;
    }).finally(() => {
      this.isLoading = false;
    });
  }

  showForm(type: "create" | "update" = null) {
    this.formType = type;
    this.form.workspace = "";
  }

  setFormFields(version?: { analyser: string; ingester: string; frontend: string; feproxied: string }, analyserMemory?: string) {
    this.form.version = {
      analyser: version?.analyser,
      ingester: version?.ingester,
      frontend: version?.frontend,
      feproxied: version?.feproxied,
    };

    if (analyserMemory) {
      this.form.analyserMemory = analyserMemory;
    }
  }

  formWorkspaceChanged() {
    if (this.selectedWorkspace) {
      this.setFormFields(this.selectedWorkspace.version, this.selectedWorkspace.analyserMemory);
      this.form.forceUpdate = false;
      this.form.useHybrid = false;
    }
    this.submitSuccess = false;
    this.submitResult = "";
  }

  createWorkspace() {
    this.submitSent = true;
    this.submitResult = "";
    this.$recaptcha("createWorkspace")
      .then(recaptchaToken => {
        this.form["g-recaptcha-response"] = recaptchaToken;
        if (this.formType === "create") {
          return api.post("workspaces", this.form);
        } else if (this.formType === "update") {
          const { version, analyserMemory, forceUpdate, useHybrid } = this.form;
          return api.put(`workspaces/${this.form.workspace}`, { version, analyserMemory, forceUpdate, useHybrid }, { headers: { apiKey: this.apiKey } });
        }
      })
      .then(postResponse => {
        this.submitSuccess = true;
        this.submitResult = postResponse.data;
        this.loadCustomers();
      })
      .catch(error => {
        this.submitSuccess = false;
        this.submitResult = (error && error.response && error.response.data) || "There's been an error";
      })
      .finally(() => {
        this.form.forceUpdate = false;
        this.submitSent = false;
        this.lastVersionUsed = { ...this.form.version };
      });
  }

  approveCustomer(customer: { key: string; status: string }) {
    this.errorMessage = "";
    customer.status = "Approving...";
    api
      .post(`customers/${customer.key}/approve`, {}, { headers: { apiKey: this.apiKey } })
      .then(() => {
        this.loadCustomers();
      })
      .catch(error => {
        const errorMsg = (error.response && error.response.data && error.response.data.message) || error.message;
        this.errorMessage = `Cannot approve customer: ${errorMsg}`;
      });
  }

  updateCustomerWorkspaceState(customer: { key: string; workspace: Record<string, any> }) {
    api
      .get(`workspaces/${customer.key}`, { headers: { apiKey: this.apiKey } })
      .then(response => {
        customer.workspace = response.data.counts;
      })
      .catch(error => {
        const errorMsg = (error.response && error.response.data && error.response.data.message) || error.message;
        this.errorMessage = `Cannot get workspace data for ${customer.key}: ${errorMsg}`;
      });
  }
}
